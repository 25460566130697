import React, { Component } from 'react'
import styled from 'styled-components'
import { SectionTitle, Paragraph } from 'components'
import { PrimaryButton, ImageContainer } from 'components/base'
import { viewports, device } from 'helpers/responsive'

const Container = styled.div`
  display: flex;
  width: 75%;
  height: 100%;
  flex-direction: row;
  ${viewports.small} {
    flex-direction: column;
    padding-top: 30px;
    width: 100%;
  }
`

const LeftContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${viewports.small} {
    order: 2;
    align-items: center;
    height: 410px;
    width: 100%;
  }
`

const RightContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  ${viewports.small} {
    ${viewports.small} {
    align-items: center;
    justify-content: center;
    order: 1;
    margin-left: 0;
    height: 355px;
    width: 100%;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${viewports.small} {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const RequestProposeButton = styled(PrimaryButton)`
  width: 210px;
  margin-top: 15px;
  ${viewports.small} {
    align-self: center;
  }
  ${device.laptopL} {
    width: 290px;
    height: 65px;
    line-height: 65px;
  }
`

const InnovationImageContainer = styled(ImageContainer)`
  width: 422px;
  height: 346px;
  background-size: cover;
  background-position: center;
  ${viewports.small} {
    margin-top: 0;
    width: 353px;
    height: 293px;
  }
  ${device.laptopL} {
    width: 600px;
    height: 480px;
  }
`


const B = (props) => <label style={{fontWeight: 'bold'}}>{props.children}</label>

export default class ResearchAndInnovation extends Component {
  render() {
    return (
      <Container>
        <LeftContainer>
          <TextContainer>
            <SectionTitle>PESQUISA E INOVAÇÃO</SectionTitle>
            <Paragraph width='450px' bigWidth='580px' marginTop='15px'>
              Em parceria com a <B>Everywhere</B>, que hoje é uma importante ferramenta de pesquisa da mídia <B>OOH, a WFerrari</B> disponibiliza uma tecnologia de alta precisão que analisa e monitora o fluxo de pessoas que passam pelos locais onde o equipamento está instalado.
            </Paragraph>
            <Paragraph width='450px' bigWidth='590px' marginTop='30px'>
              A ferramenta monitora o <B>comportamento da audiência</B>, como hábitos de fluxo, recorrência e períodos de pico, permitindo aferir os impactos e o CPM e relatórios que proporcionam uma distribuição e calibragem mais inteligente dos investimentos das campanhas.
            </Paragraph>
            <RequestProposeButton href='/#contact-us'>
              SOLICITAR PROPOSTA
            </RequestProposeButton>
          </TextContainer>
        </LeftContainer>
        <RightContainer>
          <InnovationImageContainer imageName='research-and-innovation'/>
        </RightContainer>
      </Container>
    )
  }
}
