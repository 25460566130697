import React, { Component } from 'react'
import styled from 'styled-components'
import InputMask from "react-input-mask"
import { PrimaryButton, ImageContainer } from 'components/base'
import { viewports, device } from 'helpers/responsive'
import emailjs from 'emailjs-com'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
`

const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`

const LeftContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
  ${viewports.small} {
    margin-right: 0;
    align-items: center;
    width: 100%;
  }
`

const RightContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  margin-left: 20px;
  align-items: center;
  ${viewports.small} {
    width: 0;
    height: 0;
    margin-left: 0;
  }
`

const FormContainer = styled.div`
  display: flex;
  width: 361px;
  flex-direction: column;
  ${device.laptopL} {
    width: 478px;
  }

`

const Input = styled.input`
  height: 24px;
  width: 341px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 7px;
  text-transform: uppercase;
  ${device.laptopL} {
    height: 35px;
    font-size: 15px;
    width: 456px;
  }
`

const MaskedInput = styled(InputMask)`
  height: 24px;
  width: 341px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 7px;
  text-transform: uppercase;
  ${device.laptopL} {
    height: 35px;
    font-size: 15px;
    width: 456px;
  }
`

const MessageInput = styled.textarea`
  height: 50px;
  width: 342px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 7px;
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  ${device.laptopL} {
    height: 75px;
    font-size: 15px;
    width: 458px;
  }
`

const DefaultLabel = styled.label`
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  ${device.laptopL} {
    font-size: 16px;
  }
`

const PhoneNumberLabel = styled.label`
  color: var(--primary-color);
  font-weight: bold;
  margin-top: 10px;
  font-size: 28px;
  ${device.laptopL} {
    font-size: 35px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-color);
  height: 95px;
  margin-bottom: 15px;
  background-color: rgba(32,31,29,.6);
  ${device.laptopL} {
    height: 110px;
  }
`

const Title = styled.label`
  color: #fff;
  font-size: 45px;
  font-weight: bold;
  letter-spacing: -2px;
  ${device.laptopL} {
    font-size: 55px;
  }
`

const SubTitle = styled.label`
  color: var(--primary-color);
  font-size: 11px;
  ${device.laptopL} {
    font-size: 14px;
  }
`

const SubmitButton = styled(PrimaryButton)`
  width: 101%;
  ${device.laptopL} {
    width: 480px;
  }
`

const HelpDeskImageContainer = styled(ImageContainer)`
  width: 450px;
  height: 367px;
  ${device.laptopL} {
    width: 533px;
    height: 442px;
  }
`

const MailLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #FFF
`

const LogoLinkPlay = styled(ImageContainer)`
  width: 128px;
  height: 26px;
  margin-top: 16px;
`

export default class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        name: '',
        email: '',
        phone: '',
        message: ''
      }
    }
    this.sendMail = this.sendMail.bind(this);
  }

  updateInput(e) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value.toUpperCase()
      }
    })
  }

  sendMail = async () => {
    const { name, email, phone, message } = this.state.formData
    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_NAME,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          name,
          email,
          phone,
          message
        },
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      window.alert('Solicitação enviada.\nObrigado por entrar em contato!')
      window.location.reload()
    }
    catch (error) {
      console.log(error)
      window.alert(`Ocorreu um erro: ${error.text}.\nPor favor tente novamente em alguns instantes.`)
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.sendMail()
    this.setState({
      formSubmitted: true
    })
  }

  renderForm() {
    const { name, email, phone, message } = this.state.formData

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <FormContainer>
          <TitleContainer>
            <Title>CONTATO</Title>
            <SubTitle>PREENCHA OS CAMPOS ABAIXO</SubTitle>
          </TitleContainer>

          <Input
            placeholder='NOME'
            name='name'
            onChange={this.updateInput.bind(this)}
            value={name}
            required
          />
          <Input
            placeholder='EMAIL'
            name='email'
            type='email'
            onChange={this.updateInput.bind(this)}
            value={email}
            required
          />
          <MaskedInput
            placeholder='TELEFONE'
            mask='(99) 99999-9999'
            name='phone'
            onChange={this.updateInput.bind(this)}
            value={phone}
            required
          />
          <MessageInput
            placeholder='COMO PODEMOS AJUDAR?'
            name='message'
            onChange={this.updateInput.bind(this)}
            value={message}
            required
          />
          <SubmitButton as='button' type='submit'>
            SOLICITAR PROPOSTA
          </SubmitButton>
        </FormContainer>
      </form>
    )
  }

  render() {
    return (
      <Container>
        <TopContainer>
          <LeftContainer>
            {this.renderForm()}
          </LeftContainer>
          <RightContainer>
            <HelpDeskImageContainer imageName='help-desk' />
          </RightContainer>
        </TopContainer>
        <BottomContainer>
          <DefaultLabel>R. Artur de Azevedo, 1767 - Pinheiros, São Paulo - SP, 05404-014</DefaultLabel>
          <DefaultLabel>
            <MailLink href='mailto:wilson@wferrari.com.br'>wilson@wferrari.com.br</MailLink>
            &nbsp;|&nbsp;
            <MailLink href='mailto:iolanda@wferrari.com.br'>iolanda@wferrari.com.br</MailLink>
          </DefaultLabel>
          <PhoneNumberLabel>(11) 3067-5963</PhoneNumberLabel>
          <a href='https://www.linkplay.com.br/marketing' target='blank'><LogoLinkPlay imageName='logo-linkplay'/></a>
        </BottomContainer>
      </Container>
    )
  }
}
