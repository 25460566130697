import React, { Component } from 'react'
import styled from 'styled-components'
import { SectionTitle, Paragraph } from 'components'
import { PrimaryButton, ImageContainer } from 'components/base'
import { viewports, device } from 'helpers/responsive'

const Container = styled.div`
  display: flex;
  width: 75%;
  height: 100%;
  flex-direction: row;
  ${viewports.small} {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  ${device.laptopL} {
    width: 85%;
  }
`

const LeftContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${viewports.small} {
    align-items: center;
    order: 2;
    height: 400px;
    width: 100%;
    align-items: top;
  }
`

const RightContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  ${viewports.small} {
    order: 1;
    height: 355px;
    width: 120%;
  }
`

const TextContainer = styled.div`
  display: flex;
  width: 450px;
  flex-direction: column;
  ${viewports.small} {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    width: 350px;
  }
`

const RequestProposeButton = styled(PrimaryButton)`
  width: 290px;
  margin-top: 15px;
  ${viewports.small} {
    align-self: center;
  }
  ${device.laptopL} {
    width: 375px;
    height: 65px;
    line-height: 65px;
  }
`

const InnovationImageContainer = styled(ImageContainer)`
  width: 480px;
  height: 386px;
  position: relative;
  background-position: center;
  ${viewports.small} {
    margin-top: 0;
    width: 353px;
    height: 293px;
  }
  ${device.laptopL} {
    width: 600px;
    height: 480px;
  }

`

const ExplanationAreaTitle = styled.label`
  color: var(--primary-color);
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px;
  ${viewports.small} {
    align-self: center;
  }
  ${device.laptopL} {
    font-size: 30px;
  }
`

const TitleContainer = styled.div`
  margin-bottom: 15px;
  ${viewports.small} {
    display: flex;
    justify-content: center;
  }
`

const B = (props) => <label style={{fontWeight: 'bold'}}>{props.children}</label>

export default class OurProcess extends Component {
  render() {
    return (
      <Container>
        <LeftContainer>
          <TextContainer>
            <TitleContainer>
              <SectionTitle>NOSSO PROCESSO</SectionTitle>
            </TitleContainer>
            <Paragraph width='450px' bigWidth='600px'>
              O planejamento da WFerrari visa recomendar a composição mais adequada com o objetivo de se obter a maior cobertura e frequência média possível com o menor investimento.
            </Paragraph>
            <ExplanationAreaTitle>Cenário de atuação:</ExplanationAreaTitle>
            <Paragraph width='450px'><B>» Planejamento</B> - recomendação das mídias mais pertinentes por mercado;</Paragraph>
            <Paragraph width='450px' bigWidth='400px'><B>» Negociação</B> - compra dos melhores pontos pelo menor custo;</Paragraph>
            <Paragraph width='450px'><B>» Formatação por orçamento;</B></Paragraph>
            <Paragraph width='450px' bigWidth='600px'><B>» Formatação da RM</B> {"{informações técnicas de produção}"};</Paragraph>
            <Paragraph width='450px' bigWidth='440px'><B>» Gerenciamento da campanha;</B></Paragraph>
            <Paragraph width='450px' bigWidth='440px'><B>» Pós-venda.</B></Paragraph>
            <RequestProposeButton href='/#contact-us'>
              SOLICITAR PLANEJAMENTO
            </RequestProposeButton>
          </TextContainer>
        </LeftContainer>
        <RightContainer>
          <InnovationImageContainer imageName='our-process'/>
        </RightContainer>
      </Container>
    )
  }
}
