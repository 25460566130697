import React, { Component } from 'react'
import styled from 'styled-components'
import ImageContainer from './base/imageContainer'
import { SectionTitle } from 'components'
import { device } from 'helpers/responsive'

const Container = styled(ImageContainer) `
  width: 100%;
  display: flex;
  overflow: hidden;
  background-position: top;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  ${props => props.backgroundSize && `background-size: ${props.backgroundSize}`};
  height: ${props => props.height};
  ${props => props.withMarginBottom && 'margin-bottom: 40px'};
  ${device.laptopL} {
    ${props => props.bigHeight && `height: ${props.bigHeight}`};
  }
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 15px;
`

export default class SectionContainer extends Component {
  render() {
    const { name, withImage, children, height, jpg, title, withMarginBottom, bigHeight, backgroundSize } = this.props
    return (
      <section id={name}>
        {
          title &&
          <TitleContainer>
            <SectionTitle>{title}</SectionTitle>
          </TitleContainer>
        }
        <Container
          imageName={withImage ? name : ''}
          height={height} jpg={jpg}
          withMarginBottom={withMarginBottom}
          bigHeight={bigHeight}
          backgroundSize={backgroundSize}
        >
          {children}
        </Container>
      </section>
    )
  }
}

