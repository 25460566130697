import React, { Component } from 'react'
import {
  Header,
  ContactUs,
  ResearchAndInnovation,
  EnterprisePresentation,
  TopPresentation,
  OurProcess,
  Footer,
  Segments,
  OperationMarketing,
  Agencies,
  Customers,
  Cases } from './containers'
import { SectionContainer, SectionTitle } from 'components'
import styled from 'styled-components'
import { device, isSmartPhone } from 'helpers/responsive'

const MediaOutOfHomeTitle = styled(SectionTitle)`
  color: #FFF;
  margin-bottom: 3px;
  ${device.laptopL} {
    font-size: 50px;
  }
`

const MediaOutOfHomeSubtitle = styled.label`
  color: #FFF;
  font-size: 15px;
  ${device.laptopL} {
    font-size: 25px;
  }
`

export default class Home extends Component {

  render() {
    return (
      <React.Fragment>
        <Header />
        <SectionContainer
          height='65px'
        />
        <SectionContainer
          name='top'
          height={isSmartPhone() ? '500px' : '620px'}
          bigHeight='845px'
          backgroundSize={isSmartPhone() && '315%'}
          withImage
        >
          <TopPresentation />
        </SectionContainer>
        <SectionContainer
          name='media-out-of-home'
          height='130px'
          bigHeight='200px'
          withImage
        >
          <MediaOutOfHomeTitle>MIDIA OUT OF HOME</MediaOutOfHomeTitle>
          <MediaOutOfHomeSubtitle>DO PLANEJAMENTO À EXECUÇÃO</MediaOutOfHomeSubtitle>
        </SectionContainer>
        <SectionContainer
          name='enterprise-presentation'
          height={isSmartPhone() ? '800px' : '550px'}
          bigHeight='800px'
        >
          <EnterprisePresentation />
        </SectionContainer>
        <SectionContainer
          name='segments'
          height={isSmartPhone() ? '480px' : '375px'}
          bigHeight={'492px'}
        >
          <Segments />
        </SectionContainer>
        <SectionContainer
          name='operating-market'
          height={isSmartPhone() ? '1000px' : '600px'}
          bigHeight={'818px'}
          withImage
        >
          <OperationMarketing />
        </SectionContainer>
        <SectionContainer
          name='our-process'
          height={isSmartPhone() ? '810px' : '610px'}
          bigHeight='790px'
        >
          <OurProcess />
        </SectionContainer>
        <SectionContainer
          name='agencies'
          height={isSmartPhone() ? '900px' : '745px'}
          bigHeight={'974px'}
          title='AGÊNCIAS ATENDIDAS'
          withMarginBottom
        >
          <Agencies />
        </SectionContainer>
        <SectionContainer
          name='customers'
          height={isSmartPhone() ? '600px' : '400px'}
          bigHeight='660px'
          title='CLIENTES ATENDIDOS'
          withMarginBottom
        >
          <Customers />
        </SectionContainer>
        <SectionContainer
          name='research-and-innovation'
          height={isSmartPhone() ? '830px' : '500px'}
          bigHeight='700px'
        >
          <ResearchAndInnovation />
        </SectionContainer>
        <SectionContainer
          name='cases' height={isSmartPhone() ? '260px' : '211px'}
          bigHeight='280px' title='CASES INTERNACIONAIS'
        >
          <Cases />
        </SectionContainer>
        <SectionContainer
          name='contact-us'
          height={isSmartPhone() ? '550px' : '610px'}
          bigHeight='695px'
          jpg
          withImage
        >
          <ContactUs />
        </SectionContainer>
        <Footer />
      </React.Fragment>
    )
  }
}

