import React from 'react';
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import { Home } from './pages'
import './App.css'

const GlobalStyles = createGlobalStyle`
  ${reset}
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Lato, sans-serif;
  }
`

function App() {
  return (
    <React.Fragment>
      <GlobalStyles />
      <Home />
    </React.Fragment>
  );
}

export default App;
