import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;

export default class ImageContainer extends Component {
  loadImage() {
    const { imageName, jpg } = this.props;
    const image = imageName !== '' ? require(`../../assets/${imageName}.${jpg ? 'jpg' : 'png'}`) : '';
    return image;
  }

  render() {
    const { children, className } = this.props;
    return (
      <Container className={className} image={this.loadImage()}>
        {children}
      </Container>
    )
  }
}
