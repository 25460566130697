import styled from 'styled-components'
import { viewports, device } from 'helpers/responsive'

const SectionTitle = styled.label`
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -1px;
  ${viewports.small} {
    align-self: center;
    text-align: center;
  }
  ${device.laptopL} {
    font-size: 50px;
  }
`

export default SectionTitle
