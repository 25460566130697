import React, { Component } from 'react'
import styled from 'styled-components'
import { SectionTitle, Paragraph } from 'components'
import { PrimaryButton, ImageContainer } from 'components/base'
import { viewports, device } from 'helpers/responsive'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  ${viewports.small} {
    flex-direction: column;
    margin-top: 30px;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 53%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  ${viewports.small} {
    align-items: center;
    margin-left: 0;
    height: 458px;
    width: 100%;
  }
`

const RightContainer = styled.div`
  display: flex;
  width: 47%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
  ${viewports.small} {
    align-items: center;
    margin-left: 0;
    width: 100%;
  }
  ${device.laptopL} {
    margin-left: 40px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  ${viewports.small} {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const RequestProposeButton = styled(PrimaryButton)`
  width: 250px;
  margin-top: 15px;
  ${viewports.small} {
    align-self: center;
  }
  ${device.laptopL} {
    width: 330px;
    height: 65px;
    line-height: 65px;
  }
`

const EnterpriseImageContainer = styled(ImageContainer)`
  width: 487px;
  height: 402px;
  background-size: cover;
  position: relative;
  ${viewports.small} {
    margin-top: 0;
    width: 353px;
    height: 293px;
  }
  ${device.laptopL} {
    width: 650px;
    height: 533px;
  }
`

const B = (props) => <label style={{fontWeight: 'bold'}}>{props.children}</label>

export default class EnterprisePresentation extends Component {
  render() {
    return (
      <Container>
        <LeftContainer>
          <EnterpriseImageContainer imageName='enterprise-presentation' />
        </LeftContainer>
        <RightContainer>
        <TextContainer>
            <SectionTitle>A WFERRARI</SectionTitle>
            <Paragraph width='420px' bigWidth='531px'>
              A <B>WFerrari</B> é uma empresa especializada em mídia <B>OOH</B> e, desde 2003, vem prestando serviços de planejamento nesse segmento.
            </Paragraph>
            <Paragraph width='420px' bigWidth='531px' marginTop='30px'>
              Nesses anos de atividade a <B>WFerrari</B> estudou com profundidade cada mercado, os melhores veículos e o melhor mix de mídia <B>OOH</B>, detendo hoje profundo conhecimento desse setor.
            </Paragraph>
            <Paragraph width='420px' bigWidth='531px' marginTop='30px'>
              Sabendo da dificuldade para os departamentos de mídia atuarem nesta área, a <B>WFerrari</B> faz vistorias periódicas em cada mercado pra estar sempre atualizadas com as melhores opções. Com isso, oferece muita segurança para os planejamentos de <B>OOH</B>.
            </Paragraph>
            <RequestProposeButton href='/#contact-us'>
              SOLICITAR PLANEJAMENTO
            </RequestProposeButton>
          </TextContainer>
        </RightContainer>
      </Container>
    )
  }
}
