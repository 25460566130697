import styled from 'styled-components'
import { viewports, device } from 'helpers/responsive'

const Paragraph = styled.p`
  font-size: 17px;
  width: ${props => props.width};
  line-height: normal;
  ${props => props.marginTop && `margin-top: ${props.marginTop}`};
  ${viewports.small} {
    width: 100%;
    text-align: justify;
  }
  ${device.laptopL} {
    font-size: 22px;
    ${props => props.bigWidth && `width: ${props.bigWidth}`};
  }
`

export default Paragraph
