import React, { Component } from 'react'
import styled from 'styled-components'
import { ImageContainer } from 'components/base'
import { isSmartPhone, viewports } from 'helpers/responsive'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`

const ContentRowColumn = styled.div`
  width: ${props => props.fullWidth ? '100%' : '75%'};
  height: 100%;
  display: flex;
  flex-direction: row;
`

const Image = styled(ImageContainer)`
  width: 100%;
  background-size: 101%;
  ${props => !props.fullWidth && props.number === '1' && 'background-size: 50%'};
  ${props => !props.fullWidth && props.number === '2' && 'background-size: 150%; margin-left: -40px;'};
  ${props => !props.fullWidth && props.number === '8' && 'background-size: 55%'};
  ${props => !props.fullWidth && props.number === '9' && 'background-size: 55%'};
  ${viewports.small} {
    ${props => !props.fullWidth && props.number === '2' && 'margin-left: 0'};
  }
  &:not(:last-child) {
    ${props => !props.fullWidth && 'margin-right: 30px'};
  }
`

const AgenciesContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  ${viewports.small} {
    height: 40%;
  }
`

const SamplesContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60%;
  flex-direction: column;
  ${viewports.small} {
    height: 60%;
  }
`

export default class Agencies extends Component {

  renderImages(firstNumber, lastNumber, path, fullWidth) {
    let numbers = []
    for(var i = firstNumber; i <= lastNumber; i++) {
      numbers.push(i.toString());
    }
    return (
      <ContentRowColumn fullWidth={fullWidth}>
        {
          numbers.map((number) => {
            return (
              <Image
                imageName={`agencies/${path}/${number}`}
                number={number}
                fullWidth={fullWidth}
              />
            )
          })
        }
      </ContentRowColumn>
    )
  }

  render() {
    return (
      <Container>
        <AgenciesContainer>
          {
            isSmartPhone() ?
              [
                this.renderImages(1, 2, 'logos'),
                this.renderImages(3, 4, 'logos'),
                this.renderImages(5, 6, 'logos'),
                this.renderImages(7, 8, 'logos'),
                this.renderImages(9, 10, 'logos')
              ]
            :
              [
                this.renderImages(1, 5, 'logos'),
                this.renderImages(6, 10, 'logos')
              ]
          }
        </AgenciesContainer>
        <SamplesContainer>
          {
            isSmartPhone() ?
              [
                this.renderImages(1, 3, 'samples', true),
                this.renderImages(4, 6, 'samples', true),
                this.renderImages(7, 9, 'samples', true),
                this.renderImages(10, 12, 'samples', true)
              ]
            :
              [
                this.renderImages(1, 6, 'samples', true),
                this.renderImages(7, 12, 'samples', true)
              ]
          }
        </SamplesContainer>
      </Container>
    )
  }
}

