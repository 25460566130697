import styled from 'styled-components'
import { viewports, device } from 'helpers/responsive'

const PrimaryButton = styled.a`
  background-color: var(--primary-color);
  cursor: pointer;
  border: 0;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  letter-spacing: -1px;
  ${device.laptopL} {
    font-size: 18px;
  }
  ${viewports.small} {
    letter-spacing: 0;
  }
`

export {
  PrimaryButton
}
