import React, { Component } from 'react'
import styled from 'styled-components'
import { Logo } from 'components'

const Container = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  justify-content: center;
`
export default class Footer extends Component {
  render() {
    return (
      <Container>
        <Logo />
      </Container>
    )
  }
}

