import React, { Component } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from 'components/base'
import { viewports, device, isSmartPhone } from 'helpers/responsive'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
`

const RightContainer = styled.div`
  display: flex;
  width: 38%;
  height: 280px;
  flex-direction: column;
  margin-top: 165px;
  ${viewports.small} {
    margin-top: 30px;
    width: 100%;
    height: 100%;
    align-items: center;
    margin-top: 100px;

  }
  ${device.laptopL} {
    margin-top: 215px;
    width: 39%;
  }
`

const Title = styled.label`
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  width: 225px;
  margin-bottom: 8px;
  ${device.laptopL} {
    font-size: 60px;
    width: 341px;
  }
  ${viewports.small} {
    text-align: center;
  }
`

const SubTitle = styled.label`
  color: #fff;
  width: 245px;
  font-size: 20px;
  line-height: normal;
  ${device.laptopL} {
    width: 345px;
    font-size: 30px;
  }
  ${viewports.small} {
    text-align: center;
    font-weight: bold;
  }
`

const ColoredLabel = styled.label`
  color: var(--primary-color);
  font-weight: bold;
  ${viewports.small} {
    text-align: center;
  }
`

const RequestProposeButton = styled(PrimaryButton)`
  width: 120px;
  height: 40px;
  line-height: 40px;
  margin-top: 15px;
  ${device.laptopL} {
    width: 180px;
    line-height: 60px;
    height: 60px;
  }
`

const MediaOutOfHome = styled.label`
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  margin-bottom: 13px;
`

export default class TopPresentation extends Component {
  render() {
    return (
      <Container>
        <RightContainer>
          {
            isSmartPhone() &&
              <MediaOutOfHome>
                MEDIA OUT OF HOME
              </MediaOutOfHome>
          }
          <Title>
            SUA MARCA EM <ColoredLabel>DESTAQUE</ColoredLabel>
          </Title>
          <SubTitle>
            Desde 2003 entregando o melhor em <ColoredLabel>OOH</ColoredLabel> para nossos clientes
          </SubTitle>
          <RequestProposeButton href='/#contact-us'>
            SAIBA MAIS
          </RequestProposeButton>
        </RightContainer>
      </Container>
    )
  }
}
