import React, { Component } from 'react'
import styled from 'styled-components'
import { ImageContainer } from 'components/base'
import { isSmartPhone, viewports } from 'helpers/responsive'


const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`

const ContentRowColumn = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: row;
`

const Image = styled(ImageContainer)`
  width: 100%;
  height: 100%;
  background-size: 70%;
  ${props => ['2', '9', '10'].includes(props.number) && 'background-size: 90%'};
  ${props => props.number === '6' && 'background-size: 65%'};
  ${props => props.number === '11' && 'background-size: 40%'};
  ${props => props.number === '12' && 'background-size: 48%'};
  ${props => props.number === '13' && 'background-size: 55%'};
  ${props => props.number === '14' && 'background-size: 60%'};
  ${props => props.number === '15' && 'background-size: 55%'};
  ${props => props.number === '16' && 'background-size: 110%'};
  ${props => props.number === '17' && 'background-size: 100%'};
  ${viewports.small} {
    background-size: 100%;
    ${props => ['2', '9', '10'].includes(props.number) && 'background-size: 110%'};
    ${props => props.number === '6' && 'background-size: 75%'};
    ${props => props.number === '11' && 'background-size: 70%'};
    ${props => props.number === '12' && 'background-size: 78%'};
    ${props => props.number === '13' && 'background-size: 75%'};
    ${props => props.number === '14' && 'background-size: 75%'};
    ${props => props.number === '15' && 'background-size: 70%'};
    ${props => props.number === '16' && 'background-size: 130%'};
    ${props => props.number === '17' && 'background-size: 120%'};
    ${props => props.number === '19' && 'background-size: 70%'};
    ${props => props.number === '20' && 'background-size: 60%'};
  }
  &:not(:last-child) {
    margin-right: 40px;
  }
`

export default class Customers extends Component {

  renderImages(firstNumber, lastNumber) {
    let numbers = []
    for(var i = firstNumber; i <= lastNumber; i++) {
      numbers.push(i.toString());
    }
    return (
      <ContentRowColumn>
        {
          numbers.map((number) => {
            return (
              <Image
                imageName={`customers/${number}`}
                number={number}
              />
            )
          })
        }
      </ContentRowColumn>
    )
  }

  render() {
    return (
      <Container>
        {
          isSmartPhone() ?
            [
              this.renderImages(1, 3),
              this.renderImages(4, 6),
              this.renderImages(7, 9),
              this.renderImages(10, 12),
              this.renderImages(13, 15),
              this.renderImages(16, 18),
              this.renderImages(19, 20)
            ]
          :
            [
              this.renderImages(1, 5),
              this.renderImages(6, 10),
              this.renderImages(11, 15),
              this.renderImages(16, 20)
            ]
        }
      </Container>
    )
  }
}

