import { isMobile, isTablet } from 'react-device-detect'

export const isSmartPhone = () => {
  return !isTablet && isMobile
}

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1441px',
  desktop: '2560px'
}

export const device = {
  mobileS: `@media (min-width: ${size.mobileS})`,
  mobileM: `@media (min-width: ${size.mobileM})`,
  mobileL: `@media (min-width: ${size.mobileL})`,
  tablet: `@media (min-width: ${size.tablet})`,
  laptop: `@media (min-width: ${size.laptop})`,
  laptopL: `@media (min-width: ${size.laptopL})`,
  desktop: `@media (min-width: ${size.desktop})`,
  desktopL: `@media (min-width: ${size.desktop})`
};

export const viewports = {
  big: '@media (min-width: 1280px)',
  base: '@media (max-width: 1279px and min-width: 1025px)',
  medium: '@media (max-width: 1024px and min-width: 681px)',
  small: '@media (max-width: 680px)',
  nano: '@media (max-width: 428px)',
}
