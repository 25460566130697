import React, { Component } from 'react'
import { SectionTitle, Paragraph } from 'components'
import { ImageContainer } from 'components/base'
import styled from 'styled-components'
import { device, viewports } from 'helpers/responsive'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  ${device.laptopL} {
    height: 160px;
    align-items: center;
  }
`

const Title = styled(SectionTitle)`
  color: var(--primary-color);
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  ${viewports.small} {
    flex-direction: column;
  }
`

const NationalContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: flex-end;
  padding-right: 35px;
  ${device.laptopL} {
    padding-right: 90px;
  }
  ${viewports.small} {
    width: 100%;
    justify-content: center;
  }
`

const InternationalContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  padding-left: 30px;
  ${viewports.small} {
    width: 100%;
    padding: 0;
    justify-content: center;
  }
`

const NationalImage = styled(ImageContainer)`
  width: 365px;
  height: 230px;
  margin-top: 20px;
  ${device.laptopL} {
    width: 460px;
    height: 276px;
    margin-bottom: 5px;
  }
`

const InternationalImage = styled(ImageContainer)`
  width: 365px;
  height: 255px;
  ${device.laptopL} {
    width: 460px;
    height: 300px;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const DescriptionTitle = styled.label`
  color: var(--primary-color);
  font-weight: bold;
  margin-top: 25px;
  font-size: 25px;
  ${device.laptopL} {
    font-size: 40px;
  }
`

const DescriptionText = styled(Paragraph)`
  color: #FFF;
  font-size: 15px;
  width: 250px;
  text-align: center;
  ${device.laptopL} {
    font-size: 20px;
    width: 350px;
  }
`

export default class OperatinfMarketing extends Component {
  render() {
    return (
      <Container>
        <TitleContainer>
          <Title>
            MERCADO DE ATUAÇÃO
          </Title>
        </TitleContainer>
        <ContentContainer>
          <NationalContainer>
            <DescriptionContainer>
              <NationalImage imageName='national'/>
              <DescriptionTitle>NACIONAL</DescriptionTitle>
              <DescriptionText>
                Cobrimos todos os Municípios do país, considerando os melhores formatos em cada região para uma melhor performance regional das campanhas
              </DescriptionText>
            </DescriptionContainer>
          </NationalContainer>
          <InternationalContainer>
          <DescriptionContainer>
              <InternationalImage imageName='international'/>
              <DescriptionTitle>INTERNACIONAL</DescriptionTitle>
              <DescriptionText>
                Em associação com Mediakeys, com sede em Paris desde 2009, atendemos importantes mercados: EUA, America Latina, Europa e Asia
              </DescriptionText>
            </DescriptionContainer>
          </InternationalContainer>
        </ContentContainer>
      </Container>
    )
  }
}
