import React, { Component } from 'react'
import styled from 'styled-components'
import { ImageContainer } from 'components/base'
import { isSmartPhone, viewports } from 'helpers/responsive'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${viewports.small} {
    flex-direction: column;
  }
`

const ContentRowColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`

const Image = styled(ImageContainer)`
  width: 100%;
  background-size: 101%;
`

export default class Cases extends Component {

  renderImages(firstNumber, lastNumber) {
    let numbers = []
    for(var i = firstNumber; i <= lastNumber; i++) {
      numbers.push(i.toString());
    }
    return (
      <ContentRowColumn>
        {
          numbers.map((number) => {
            return (
              <Image imageName={`cases/${number}`} number={number}/>
            )
          })
        }
      </ContentRowColumn>
    )
  }

  render() {
    return (
      <Container>
        {
          isSmartPhone() ?
            [
              this.renderImages(1, 3),
              this.renderImages(4, 6)
            ]
          :
            this.renderImages(1, 6)
        }

      </Container>
    )
  }
}

