import React, { Component } from 'react'
import styled from 'styled-components'
import { SectionTitle } from 'components'
import { ImageContainer } from 'components/base'
import { isSmartPhone } from 'helpers/responsive'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 45px;
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const ContentRowColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`

const HighlightLabel = styled.label`
  color: var(--primary-color);
`

const Image = styled(ImageContainer)`
  width: 100%;
  height: 100%;
  background-size: cover;
`

export default class Segments extends Component {
  renderImages(firstNumber, lastNumber) {
    let numbers = []
    for(var i = firstNumber; i <= lastNumber; i++) {
      numbers.push(i.toString());
    }
    return (
      <ContentRowColumn>
        {
          numbers.map((number) => {
            return (
              <Image imageName={`segments/${number}`} />
            )
          })
        }
      </ContentRowColumn>
    )
  }

  render() {
    return (
      <Container>
        <TitleContainer>
          <SectionTitle>
            Atuamos nos seguintes segmentos de <HighlightLabel>Mídia OOH</HighlightLabel>
          </SectionTitle>
        </TitleContainer>
        <ContentContainer>
          {
            isSmartPhone() ?
              [
                this.renderImages(1, 3),
                this.renderImages(4, 6),
                this.renderImages(7, 9),
                this.renderImages(10, 12)
              ]
            :
              [
                this.renderImages(1, 6),
                this.renderImages(7, 12)
              ]
          }
        </ContentContainer>
      </Container>
    )
  }
}

