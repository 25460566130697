import React from 'react';
import styled from 'styled-components'
import { ImageContainer } from './base'

const LogoContainer = styled(ImageContainer)`
  height: 25px;
  width: 185px;
  background-size: contain;
`
const Logo = () => {
  return (
    <LogoContainer imageName = 'logo' />
  )
}

export default Logo
