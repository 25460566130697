import React, { Component } from 'react'
import styled from 'styled-components'
import BaseLogo from 'components/logo'
import { viewports, device } from 'helpers/responsive'
import { TiThMenu } from 'react-icons/ti'
import { MdClose } from 'react-icons/md'
import { isMobile, isTablet } from 'react-device-detect'
import Drawer from '@material-ui/core/Drawer'

const Container = styled.header`
  background-color: #fff;
  display: flex;
  align-items: center;
  height: var(--height-header);
  font-weight: bold;
  font-size: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 5px;
  position: fixed;
  z-index: 1;
  width: 98%;
  ${viewports.small} {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
`
const LinksContainer = styled.div`
  width: 100%;
`

const RedirectLink = styled.a`
  color: #a5a5a5;
  margin-right: 12px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  ${viewports.small} {
    font-size: 20px;
    line-height: 40px;
  }
  ${device.laptopL} {
    font-size: 15px;
  }
}
`

const PhoneContainer = styled.div`
  display: flex;
`

const PhoneLabelContainer = styled.div`
  text-align: right;
`

const PhoneLabel = styled.a`
  color: #fff;
  background-color: var(--primary-color);
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  ${device.laptopL} {
    font-size: 15px;
  }
`

const PhoneNumberContainer = styled.div`
  color: var(--primary-color);
  margin-left: 5px;
  width: 141px;
  display: flex;
  ${device.laptopL} {
    width: 200px;
  }
`

const PhoneNumber = styled.label`
  align-self: center;
  font-size: 13px;
  ${device.laptopL} {
    font-size: 18px;
  }
`

const LogoContainer = styled.div`
  height: 30px;
  width: 150px;
  margin-right: 120px;
  ${viewports.small} {
    margin-right: 170px;
  }
`

const Logo = styled(BaseLogo)`
  background-size: 60%;
`

const MenuContainer = styled.div`
  display: flex;
  width: 300px;
  height: 100%;
  flex-direction: column;
  padding: 20px;
  ${viewports.small} {
    width: 200px;
  }
`

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

const MenuItem = styled.div`
  display: flex;
  text-align: center;
`

const MenuTopContainer = styled.div`
  width: 100%;
`

const RightButton = styled.div`
  display: ${props => props.hidden? 'hidden' : 'flex'};
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 3px;
  border: 2px solid transparent;
  margin-right: 8px;
  overflow: hidden;
  cursor: pointer;
  float: right;
  &:hover {]
    border: ${props => props.withHover && '2px solid #FFF'};
  }
  ${viewports.small} {
    height: 30px;
    width: 30px;
  }
`

const BurgerIcon = styled(TiThMenu)`
  width: 80%;
  height: 80%;
  fill: '#696969';
`

const CloseMenuIcon = styled(MdClose)`
  width: 100%;
  height: 100%;
`

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openMenu: false
    }
  }

  toggleMenu() {
    const { openMenu } = this.state
    this.setState({ openMenu: !openMenu })
  }

  isSmartPhone() {
    return !isTablet && isMobile
  }

  renderPhoneContainer() {
    return (
      <PhoneContainer>
        <PhoneLabelContainer>
          <PhoneLabel href='/#contact-us'>SOLICITE UM</PhoneLabel>
          <PhoneLabel href='/#contact-us'>PLANEJAMENTO</PhoneLabel>
        </PhoneLabelContainer>
        <PhoneNumberContainer>
          <PhoneNumber>(11) 3067-5963</PhoneNumber>
        </PhoneNumberContainer>
      </PhoneContainer>
    )
  }
  renderItemsDesktop() {
    return(
      [
        <LinksContainer>
          <RedirectLink onClick={() => this.goToLocation('/#top')}>INÍCIO</RedirectLink>
          <RedirectLink onClick={() => this.goToLocation('/#enterprise-presentation')}>EMPRESA</RedirectLink>
          <RedirectLink onClick={() => this.goToLocation('/#segments')}>SEGMENTOS</RedirectLink>
          <RedirectLink onClick={() => this.goToLocation('/#operating-market')}>MERCADO</RedirectLink>
          <RedirectLink onClick={() => this.goToLocation('/#our-process')}>PROCESSO</RedirectLink>
          <RedirectLink onClick={() => this.goToLocation('/#customers')}>CLIENTES</RedirectLink>
          <RedirectLink onClick={() => this.goToLocation('/#research-and-innovation')}>INOVAÇÃO</RedirectLink>
          <RedirectLink onClick={() => this.goToLocation('/#cases')}>CASES</RedirectLink>
          <RedirectLink onClick={() => this.goToLocation('/#contact-us')}>CONTATO</RedirectLink>
        </LinksContainer>,
        this.renderPhoneContainer()
      ]
    )
  }

  renderItemsSmartPhone() {
    return (
      <BurgerIcon onClick={this.toggleMenu.bind(this)}/>
    )
  }

  goToLocation(href) {
    window.location.href = href
    window.scrollTo(0, window.pageYOffset - 65)
  }

  renderMenuItem(href, label) {
    return (
      <MenuItem onClick={this.toggleMenu.bind(this)}>
        <RedirectLink onClick={() => this.goToLocation(href)}>{label}</RedirectLink>
      </MenuItem>

    )
  }
  render() {
    const { openMenu } = this.state
    return (
      <Container>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        { this.isSmartPhone() ? this.renderItemsSmartPhone() : this.renderItemsDesktop() }
        {
          openMenu &&
          <Drawer
            anchor='right'
            open={openMenu}
            onClose={this.toggleMenu.bind(this)}>
            <MenuContainer>
              <MenuTopContainer>
                <RightButton onClick={this.toggleMenu.bind(this)}>
                  <CloseMenuIcon/>
                </RightButton>
              </MenuTopContainer>
              <MenuItemContainer>
                {this.renderMenuItem('/', 'INICIO')}
                {this.renderMenuItem('/#enterprise-presentation', 'EMPRESA')}
                {this.renderMenuItem('/#segments', 'SEGMENTOS')}
                {this.renderMenuItem('/#operating-market', 'MERCADO')}
                {this.renderMenuItem('/#our-process', 'PROCESSO')}
                {this.renderMenuItem('/#customers', 'CLIENTES')}
                {this.renderMenuItem('/#research-and-innovation', 'INOVAÇÃO')}
                {this.renderMenuItem('/#cases', 'CASES')}
                {this.renderMenuItem('/#contact-us', 'CONTATO')}
              </MenuItemContainer>
            </MenuContainer>
          </Drawer>
        }
      </Container>
    )
  }
}

